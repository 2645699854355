import React from "react"
import Layout from "../components/layout"
import Header1 from "../components/header1"
import FAQDetails from "../components/faq-details"

export default function Imprint() {
  return (
    <Layout fullWave="false" bottomWave="false" callToAction="true">
      <section className="bg-white py-20">
        <div className="container max-w-5xl mx-auto m-8">
          <Header1 headerText="Frequenly Asked Questions" />
        </div>

        <div className="max-w-5xl mx-auto m-8 text-gray-500 text-xl text-center">
          <p className="text-center">
            The most common questions about how LinkFive works and what we can
            do for you.
          </p>

          <section className="text-gray-700 text-left">
            <div className="container px-5 mx-auto">
              <div className="flex flex-wrap sm:mx-auto sm:mb-2 -mx-2">
                <div className="w-full px-4 py-2 my-8">

                  <FAQDetails title="What App Stores are supported?">
                        At launch, we will fully support Apple's App Store and Google's Play Store for subscription analytics (currently in Beta).
                        Amazon, Samsung and Huawei store connections are next on our roadmap.
                  </FAQDetails>

                  <FAQDetails title="What does the name LinkFive mean?">
                        Our initial plan is to be able to manage subscriptions across at least five app stores - you can <span className="italic">link five</span> stores :)
                  </FAQDetails>

                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Layout>
  )
}
