import React from "react"

export default function FAQDetails(props) {
  return (
    <details className="mb-8">
      <summary className="font-semibold focus:outline-none py-2 px-4 highlight cursor-pointer">
        {props.title}
      </summary>

      <span>
        <p className="pl-9">{props.children}</p>
      </span>
    </details>
  )
}
